.main_heading {
    font-size: 46px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
}

.box1 {
    background-color: var(--lGreen);
    width: 35%;
    height: 95%;
    position: absolute;
    top: 18%;
    right: 0;
    z-index: -2;
}

.box2 {
    background-color: var(--dGreen);
    width: 4%;
    height: 90%;
    position: absolute;
    top: 45%;
    right: 0;
    z-index: -1;
}

.hero_image {
    position: relative;
    left: 0;
    width: 115%;
    min-height: 245px;
}

.separation {
    border-left: 10px solid var(--primary);
    border-right: 10px solid var(--primary);
}

.icon_wrap {
    width: 28px;
    height: 28px;
    padding: 6px;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}

.plant_image {
    position: absolute;
    top: -24%;
    height: 140%;
    right: 0;
}

@media (max-width: 991px) {
    .main_heading {
        font-size: 28px;
    }

    .hero_image {
        position: relative;
        left: 0;
        width: 100%;
        margin-bottom: 4.5rem;
        margin-top: 1rem
    }

    .box1 {
        width: 100%;
        height: 30%;
        top: 18%;
        right: 0;
    }

    .box2 {
        width: 40%;
        height: 40%;
        top: -10%;
        right: 0;
    }

    .separation {
        border-left: 0;
        border-right: 0;
        border-top: 10px solid var(--primary);
        border-bottom: 10px solid var(--primary);
    }

    .plant_image {
        position: relative;
        top: 0%;
        width: 100%;
        height: auto;
        min-height: 340px;
    }
}