.navbar_container {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 3;
    background-color: var(--primary);
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0 12px 0;
}

.navbar_logo {
    object-fit: contain;
    height: 40px;
}

.navbar_right {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 2px;
}

.navbar_right>li>a {
    text-decoration: none !important;
    color: var(--grey);
    padding-right: 25px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
}

.navbar_right>li {
    list-style: none;
}

.hamburgerMenu {
    position: absolute;
    right: 0;
    font-size: 32px;
}

.navbar_right a:hover {
    color: var(--dGreen) !important;
}

@media (max-width: 991px) {
    .navbar_right {
        /* align-items: flex-start; */
        padding-left: 0 !important;
        flex-direction: column;
        position: fixed;
        top: 80px;
        width: 100%;
        height: 100%;
        right: -100%;
        z-index: 3;
        background-color: #FFFF;
        transition: all 0.5s ease;
    }

    .navbar_right.active {
        right: 0;
        background-color: #FFFF;
        padding-top: 30px;
        overflow-x: hidden;
    }

    .navbar {
        padding-bottom: 15px;
    }

    .navbar_container {
        position: static;
        top: 0;
    }

    .navbar_logo {
        height: 50px;
    }

    .nav_mobile {
        margin: 0 12px;
        justify-content: center;
    }

    .navbar_right>li>a {
        transition: 0.3s;
        padding: 0;
        font-size: 22px;
    }

    .navbar_right>li {
        padding: 10px;
    }

    .nav_style_changer_mobile {
        position: sticky !important;
        top: 0;
    }

    .hamburgerMenu {
        display: block !important;
    }
}