:root {
    --primary: #FCF2EA;
    --lGreen: #41BA90;
    --dGreen: #0D936E;
    --sapGreen: #082719;
    --grey: #383838;
    scroll-behavior: auto;
}

html {
    font-size: 87.5%;
    font-family: 'Poppins', sans-serif;
    color: var(--grey);
}

body {
    background-color: var(--primary);
}

h2,
h5,
h6 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
}

.action_btn1 {
    background-color: var(--dGreen);
    color: #fff !important;
    font-size: 1rem;
    font-weight: 600;
    padding: 8px 11px !important;
    border: 2px solid var(--dGreen);
    border-radius: 5px;
    font-family: "Montserrat", sans-serif;
    display: inline-block;
    text-decoration: none;
}

.action_btn1:hover {
    background-color: transparent;
    color: var(--dGreen) !important;
}

.action_btn2 {
    background-color: transparent;
    color: var(--grey);
    font-size: 1rem;
    font-weight: 600;
    padding: 8px 11px;
    border: 2px solid var(--grey);
    border-radius: 5px;
    font-family: "Montserrat", sans-serif;
    display: inline-block;
    text-decoration: none;
}

.action_btn2:hover {
    border-color: var(--dGreen);
    color: var(--dGreen) !important;
}

.text_green {
    color: var(--dGreen);
}

.bg_green {
    background-color: var(--dGreen);
}

.changed_li>li {
    list-style: none;
    display: flex;
    align-items: center;
    line-height: 1.2;
}

.changed_li>li::before {
    content: "\2022";
    /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: var(--dGreen);
    /* Change the color */
    font-weight: 900;
    /* If you want it to be bold */
    display: inline-block;
    /* Needed to add space between the bullet and the text */
    width: 1em;
    /* Also needed for space (tweak if needed) */
    font-size: 2rem;
}

a:hover {
    color: var(--dGreen) !important;
}

@media (max-width: 991px) {

    .container-fluid>.text-center {
        text-align: left !important;
        margin-left: 1.5rem;
    }

    .container>.text-center {
        text-align: left !important;
    }
}