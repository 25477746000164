.box2_our_story {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: var(--dGreen);
    opacity: 0.22;
    z-index: -1;
    width: 54%;
    height: 50%;
}

.box1_our_story {
    position: absolute;
    top: 19%;
    left: 0;
    background-color: var(--lGreen);
    z-index: -1;
    width: 46%;
    height: 68%;
}

@media (max-width: 991px) {
    .box1_our_story {
        position: absolute;
        top: 44%;
        left: 0;
        background-color: var(--lGreen);
        z-index: -1;
        width: 60%;
        height: 25%;
    }

    .box2_our_story {
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: var(--dGreen);
        opacity: 0.22;
        z-index: -1;
        width: 100%;
        height: 31%;
    }
}